import create from "zustand";
import { getCookie, setCookie } from "./utils";

export const useStore = create<{
  login: boolean;
  setLogin: SetLogin;

  calendar?: string;
  setCalendar: SetCalendar;

  projects?: Projects;
  setProjects: SetProjects;

  init: boolean;
  setInit: SetInit;

  routes: Routes;
  setRoutes: SetRoutes;

  searchTerm: string;
  setSearchTerm: (searchTerm: string) => any;

  filters: Filters;
  setFilters: SetFilters;
}>((set) => ({
  login: false,
  setLogin: (login) => set((state) => ({ ...state, login })),
  calendar: getCookie("selected-google-calendar"),
  setCalendar: (calendar) => {
    setCookie("selected-google-calendar", calendar);
    return set((state) => ({ ...state, calendar }));
  },
  setProjects: (projects) => {
    return set((state) => ({ ...state, projects }));
  },
  init: true,
  setInit: (init) => set((state) => ({ ...state, init })),

  routes: [
    ...window.location.hash
      .replace(/#/g, "")
      .split("/")
      .map(decodeURIComponent),
  ] as Routes,
  setRoutes: (routes) => {
    const routeStrings = routes.filter((x) => x) as string[];
    window.location.hash = routeStrings.map(encodeURIComponent).join("/");
    return set((state) => ({ ...state, routes }));
  },
  searchTerm: "",
  setSearchTerm: (searchTerm) => {
    return set((state) => ({ ...state, searchTerm }));
  },
  setFilters: (key, value) =>
    set((state) => ({ ...state, filters: { ...state.filters, [key]: value } })),
  filters: { isDone: false },
}));
