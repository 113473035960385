import { MainTemplate } from "../Template";
import { useStore } from "../store";
import { SelectCalendar } from "./SelectCalendar";
import { CalendarEventsTable } from "./CalendarEventsTable";
import { className, getDurationInMinutes } from "../utils";

import "./Overview.scss";
import { SingleProject } from "./SingleProject";
import useSWR from "swr";

const cls = className("Overview");
const boxCls = className("Box");

export const Overview = () => {
  const calendar = useStore((state) => state.calendar);
  const routes = useStore((state) => state.routes);
  const selectedProject = routes[1];
  const setProjects = useStore((state) => state.setProjects);

  const key = `gapi.client.calendar.events/${calendar}`;
  const { error, isValidating, mutate } = useSWR(key, () =>
    !calendar
      ? null
      : gapi.client.calendar.events
          .list({
            calendarId: calendar || "",
            showDeleted: false,
            singleEvents: true,
            maxResults: 2500,
            orderBy: "startTime",
            timeMin: new Date("2023-08-01").toISOString(),
          })
          .then((x) => {
            const grouped: Projects = x.result.items.reduce(
              (acc: Projects, event) => {
                const keySplit = event.summary.match(/.+?(?=\s[^#])/);
                const key = (keySplit ? keySplit[0] : event.summary).trim();
                const eventMetaSplit = event.summary.split(/ \| /);
                const eventMeta = eventMetaSplit[1] || "";
                const memoProject = acc[key];
                const hours = getDurationInMinutes(event);
                const newDoc = {
                  key,
                  events: memoProject
                    ? [...memoProject.events, event]
                    : [event],
                  hours: memoProject ? memoProject.hours + hours : hours,
                  done:
                    (memoProject && memoProject.done) ||
                    (eventMeta.search("DONE") > -1 &&
                      eventMeta.search("NOTDONE") < 0),
                  lastUpdate: new Date(event.start.dateTime || event.updated),
                  maxHours: parseFloat(
                    eventMeta
                      .match(/H:(.*?)\s/g)
                      ?.map((x) => x.replace("H:", "").trim())
                      .join("") || "0"
                  ),
                  invoicedHours: parseFloat(
                    eventMeta
                      .match(/I:(.*?)\s/g)
                      ?.map((x) => x.replace("I:", "").trim())
                      .join("") || "0"
                  ),
                  money: parseFloat(
                    eventMeta
                      .match(/([$])\w+/g)
                      ?.map((x) => x.replace("$", ""))
                      .join("") || "0"
                  ),
                };
                return { ...acc, [key]: newDoc };
              },
              {} as Projects
            );

            setProjects(grouped);
          })
  );
  return (
    <MainTemplate>
      <>
        {error && error.toString()}
        <div className={cls("main")}>
          {!calendar ? (
            <div className="Box">
              <div className={boxCls("wrapper")}>
                <h1>Select calendar</h1>
                <p>
                  To get started, choose what calendar you are doing your time
                  tracking in.
                </p>
                <SelectCalendar hideLabel />
              </div>
            </div>
          ) : (
            <CalendarEventsTable
              collapsed={Boolean(selectedProject)}
              key={calendar}
              isValidating={isValidating}
              selectedProject={selectedProject}
            />
          )}
          {selectedProject && (
            <SingleProject
              key={selectedProject}
              selectedProject={selectedProject}
              revalidate={() => mutate()}
            />
          )}
        </div>
      </>
    </MainTemplate>
  );
};
