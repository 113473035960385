import { login } from "../googleapi";
import { className } from "../utils";

const cls = className("Box");

export const Login = () => {
  return (
    <div className="Box">
      <div className={cls("wrapper")}>
        <h1>Login</h1>
        <p>
          Fugiat deserunt nulla ea proident cupidatat. Occaecat ut adipisicing
          labore laboris culpa velit ad ea laboris nulla. Dolore fugiat laboris
          veniam exercitation aute.
        </p>
        <button onClick={login}>Click to login to your google account</button>
      </div>
    </div>
  );
};
