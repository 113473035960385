import { className } from "./utils";
import "./ProjectKey.scss";
import { useStore } from "./store";

const cls = className("ProjectKey");

export const ProjectKey = ({ project }: { project: Project }) => {
  const setSearchTerm = useStore((state) => state.setSearchTerm);
  return (
    <>
      {project.key.split(" ").map((x) => (
        <span
          onClick={(e) => {
            e.stopPropagation();
            setSearchTerm(x);
          }}
          className={"ProjectKey"}
          key={x}
        >
          <span className={cls("hash")}>#</span>
          {x.replace("#", "")}
        </span>
      ))}
    </>
  );
};
