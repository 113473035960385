import { useStore } from "./store";

type Handled = boolean;

const closeOnEscape = (e: KeyboardEvent): Handled => {
  if (e.key === "Escape") {
    const state = useStore.getState();
    if (state.routes[1]) {
      useStore.setState({ routes: [state.routes[0]] });
      return true;
    }
    if (state.searchTerm) {
      useStore.setState({ searchTerm: "" });
      return true;
    }
  }
  return false;
};

export const keyboardListeners = () => {
  document.addEventListener("keyup", (e) => {
    closeOnEscape(e);
  });
};
