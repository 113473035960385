import * as React from "react";
import { useStore } from "../store";
import { className, getDurationInHours, getEventStartDate } from "../utils";

import "./SingleProject.scss";
import { ProjectKey } from "../ProjectKey";

const cls = className("SingleProject");

export const SingleProject = ({
  selectedProject,
  revalidate,
}: {
  selectedProject: string;
  revalidate: () => any;
}) => {
  const projects = useStore((state) => state.projects);
  const setRoutes = useStore((state) => state.setRoutes);
  const project = projects && projects[selectedProject];
  const calendar = useStore((state) => state.calendar);
  const [formError, setFormError] = React.useState<string>();

  return (
    <div className={"SingleProject"}>
      {project && (
        <div className={cls("wrapper")}>
          <header className={cls("header")}>
            <h2>
              <ProjectKey project={project} />
            </h2>
            <a
              href="#overview"
              className={cls("close-button")}
              onClick={() => {
                setRoutes(["overview"]);
              }}
            >
              &times;
            </a>
          </header>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setFormError("");
              const formData = new FormData(e.currentTarget);
              const money = parseInt(formData.get("money") as string);
              const invoicedHours = parseFloat(
                formData.get("invoicedHours") as string
              );
              const maxHours = parseFloat(formData.get("maxHours") as string);
              const done = formData.get("done") ? "DONE" : "NOTDONE";
              if (
                money === undefined ||
                invoicedHours === undefined ||
                maxHours === undefined
              ) {
                return setFormError("Please fill in all data");
              }
              const event = project.events[project.events.length - 1];
              const startSummary = event.summary.split(" | ")[0];
              const summary = `${startSummary} | $${money} I:${invoicedHours} H:${maxHours} ${done}`;
              return (
                event &&
                calendar &&
                gapi.client.calendar.events
                  .update({
                    calendarId: calendar,
                    eventId: event.id,
                    resource: {
                      end: event.end,
                      start: event.start,
                      summary,
                    },
                  })
                  .then(revalidate)
                  .catch((e) => setFormError(e.toString()))
              );
            }}
            className={cls("main")}
          >
            {formError && <p className={cls("form-error")}>{formError}</p>}
            <fieldset className={cls("inputs")}>
              <label>
                Hours
                <input value={(project.hours / 60).toFixed(2)} disabled />
              </label>
              <label>
                Max hours
                <input defaultValue={project.maxHours} name="maxHours" />
              </label>
              <label>
                Invoiced hours
                <input
                  defaultValue={project.invoicedHours}
                  name="invoicedHours"
                />
              </label>
              <label>
                Money
                <input defaultValue={project.money} name="money" />
              </label>
              <label className={cls("checkbox-label")}>
                <input
                  name="done"
                  type="checkbox"
                  defaultChecked={project.done}
                />{" "}
                Done
              </label>
              <span className={cls("save-button-wrapper")}>
                <button type="submit">Save</button>
              </span>
            </fieldset>
          </form>
          <footer className={cls("table-wrapper")}>
            <h3>Events</h3>
            <table className={cls("events-table")}>
              <tbody>
                {[...project.events].reverse().map((event) => {
                  const title = event.summary.replace(project.key, "") || "-";
                  return (
                    <tr key={event.id}>
                      <td>{getEventStartDate(event)}</td>
                      <td title={title}>{title}</td>
                      <td>{getDurationInHours(event)}</td>
                    </tr>
                  );
                })}
                <tr className={cls("tr-sum")}>
                  <td></td>
                  <td></td>
                  <td>Total: {(project.hours / 60).toFixed(2)}</td>
                </tr>
              </tbody>
            </table>
          </footer>
        </div>
      )}
    </div>
  );
};
