import { useStore } from "./store";
import { login, logout } from "./googleapi";
import "./Nav.scss";
import { className } from "./utils";

const cls = className("Nav");

export const Nav = () => {
  const loggedIn = useStore((state) => state.login);
  const setRoutes = useStore((state) => state.setRoutes);
  const routes = useStore((state) => state.routes);

  return (
    <div className="Nav">
      <nav className={cls("wrapper")}>
        <ul>
          {loggedIn && (
            <>
              <li>
                <a
                  className={cls("active", routes[0] === "overview")}
                  href="#overview"
                  onClick={() => setRoutes(["overview"])}
                >
                  Overview
                </a>
              </li>
              <li>
                <a
                  className={cls("active", routes[0] === "settings")}
                  href="#settings"
                  onClick={() => setRoutes(["settings"])}
                >
                  Settings
                </a>
              </li>
            </>
          )}
          <li>
            {loggedIn ? (
              <a className={cls("logout")} onClick={logout} href="#login">
                Log out
              </a>
            ) : (
              <a
                onClick={login}
                className={cls("active", routes[0] === "login")}
                href="#logout"
              >
                Login
              </a>
            )}
          </li>
        </ul>
      </nav>
    </div>
  );
};
