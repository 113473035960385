import cookies from "js-cookie";

export const className = (base: string) => (
  sel: string,
  bool?: boolean
): string => {
  return bool !== false ? `${base}__${sel}` : "";
};

export const getCookie = (key: CookieKey) => {
  return cookies.get(key);
};

export const setCookie = (key: CookieKey, value: string) => {
  return cookies.set(key, value);
};

export const getDurationInMinutes = (
  event: gapi.client.calendar.Event
): number => {
  const start = new Date(event.start.dateTime || "").getTime();
  const end = new Date(event.end.dateTime || "").getTime();
  return (end - start) / 1000 / 60;
};

export const getDurationInHours = (
  event: gapi.client.calendar.Event
): string => {
  return (getDurationInMinutes(event) / 60).toFixed(2);
};

export const getEventStartDate = (
  event: gapi.client.calendar.Event
): string => {
  const date = new Date(event.start.dateTime || "");
  const [, ...rest] = date.toDateString().split(" ");
  const time =
    date.getHours().toString().padStart(2, "0") +
    ":" +
    date.getMinutes().toString().padStart(2, "0");

  return rest.join(" ") + " | " + time;
};
