import { useStore } from "../store";
import useSWR from "swr";

export const SelectCalendar = ({ hideLabel }: { hideLabel?: boolean }) => {
  const setCalendar = useStore((state) => state.setCalendar);
  const calendar = useStore((state) => state.calendar);
  const { data, error } = useSWR("calendars", () =>
    gapi.client.calendar.calendarList.list().then((x) => x)
  );

  return (
    <>
      {error && error.toString()}
      <label>
        {!hideLabel && <>Set calendar</>}
        <select
          value={calendar}
          onChange={(e) =>
            e.currentTarget.value && setCalendar(e.currentTarget.value)
          }
        >
          <option value="">Select calendar</option>
          {data?.result.items.map((x) => {
            return (
              <option key={x.id} value={x.id}>
                {x.summary}
              </option>
            );
          })}
        </select>
      </label>
    </>
  );
};
