import React from "react";
import { useStore } from "./store";
import { Login } from "./routes/Login";
import { Overview } from "./routes/Overview";
import { FourOFour } from "./routes/404";
import { Settings } from "./routes/Settings";

import "./App.scss";
import { className } from "./utils";
import { keyboardListeners } from "./keyboard-listener";

const cls = className("App");

keyboardListeners();

const RouteHandler = ({ routes }: { routes: Routes }) => {
  const baseRoute = routes[0];
  switch (baseRoute) {
    case "login":
      return <Login />;

    case "overview":
      return <Overview />;

    case "settings":
      return <Settings />;

    case "":
      return <Login />;

    default:
      return <FourOFour />;
  }
};

function App() {
  const init = useStore((state) => state.init);
  const routes = useStore((state) => state.routes);

  return (
    <div className={cls("wrapper")}>
      {init ? (
        <div className={cls("loading")}>Loading</div>
      ) : (
        <RouteHandler key={routes[0]} routes={routes} />
      )}
    </div>
  );
}

export default App;
