import { MainTemplate } from "../Template";

export const FourOFour = () => {
  return (
    <MainTemplate>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "95vh",
        }}
      >
        <h1>404</h1>
      </div>
    </MainTemplate>
  );
};
