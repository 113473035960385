import * as React from "react";
import { MainTemplate } from "../Template";
import { SelectCalendar } from "./SelectCalendar";

export const Settings = () => {
  return (
    <MainTemplate>
      <>
        <h1>Settings</h1>
        <SelectCalendar />
      </>
    </MainTemplate>
  );
};
