import { Nav } from "./Nav";
import { className } from "./utils";
import "./Template.sass";

const cls = className("Template");

export const MainTemplate = ({ children }: { children: JSX.Element }) => {
  return (
    <>
      <Nav />
      <main className={cls("main")}>{children}</main>
    </>
  );
};
