import { useStore } from "./store";

const INIT_CONFIG = {
  discoveryDocs: [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
  ],
  clientId:
    "247773297623-cr40vc3m8akuscbjbfavgc8hila2nfnd.apps.googleusercontent.com",
  scope: "https://www.googleapis.com/auth/calendar",
};

gapi.load("client:auth2", () => {
  gapi.client
    .init(INIT_CONFIG)
    .then(() => {
      const login = gapi.auth2.getAuthInstance().isSignedIn.get();
      useStore.setState({
        login,
        init: false,
      });
      const { setRoutes, routes } = useStore.getState();
      if (login && (routes[0] === "login" || routes[0] === "")) {
        setRoutes(["overview"]);
      }

      useStore.subscribe((state) => {
        if (!state.login && !state.init && state.routes[0] !== "login") {
          state.setRoutes(["login"]);
        }
        if (state.login && !state.init && state.routes[0] === "login") {
          state.setRoutes(["overview"]);
        }
      });

      gapi.auth2.getAuthInstance().isSignedIn.listen((login) => {
        useStore.setState({ login });
        if (login) {
          useStore.setState({ routes: ["overview"] });
        }
      });
    })
    .catch(console.error);
});

export const getGapiClient = () => console.log;

export const login = () =>
  gapi.auth2
    .getAuthInstance()
    .signIn()
    .then(() => {
      useStore.setState({
        routes: ["overview"],
      });
    });

export const logout = () => gapi.auth2.getAuthInstance().signOut();
