import * as React from "react";
import { useStore } from "./store";
import { className } from "./utils";
import "./SearchBar.scss";

const cls = className("SearchBar");

export const SearchBar = ({ isNarrow }: { isNarrow: boolean }) => {
  const searchTerm = useStore((state) => state.searchTerm);
  const setSearchTerm = useStore((state) => state.setSearchTerm);
  const setFilters = useStore((state) => state.setFilters);
  const filters = useStore((state) => state.filters);

  const [openFilter, setOpenFilter] = React.useState<boolean>(false);

  return (
    <>
      <div className={cls("wrapper") + " " + cls("wrapper--narrow", isNarrow)}>
        <div className={cls("search-wrapper")}>
          <input
            className={cls("search-input")}
            value={searchTerm}
            placeholder="Search"
            onChange={(e) => setSearchTerm(e.currentTarget.value)}
          />
          <button
            className={
              cls("reset-button") +
              " " +
              cls("reset-button--narrow", isNarrow) +
              " " +
              cls("reset-button--inactive", Boolean(!searchTerm))
            }
            onClick={() => setSearchTerm("")}
          >
            &times;
          </button>
        </div>
        <button
          onClick={() => setOpenFilter(!openFilter)}
          className={cls("filter-button")}
        >
          {!openFilter ? (
            <span
              style={{
                fontSize: "0.6em",
                display: "inline-block",
                transform: "translateY(-0.2em)",
              }}
            >
              ▼
            </span>
          ) : (
            <>&times;</>
          )}
          &nbsp;Filters
        </button>
      </div>
      {openFilter && (
        <div
          className={cls("filters") + " " + cls("filters--narrow", isNarrow)}
        >
          <label>
            <strong>Filters:</strong>{" "}
          </label>
          <label>
            <input
              onChange={() => setFilters("isDone", !filters.isDone)}
              type="checkbox"
              checked={filters.isDone}
            />
            Hide done
          </label>
          <label
            style={{ marginLeft: "auto", marginRight: 0 }}
            onClick={() => {
              setFilters("isDone", false);
              setSearchTerm("");
              setOpenFilter(false);
            }}
          >
            <strong>Reset all</strong>
          </label>
        </div>
      )}
    </>
  );
};
