import { useStore } from "../store";

import "./CalendarEventsTable.scss";
import { className } from "../utils";
import { ProjectKey } from "../ProjectKey";
import { SearchBar } from "../SearchBar";
import { useMemo } from "react";

const cls = className("CalendarEventsTable");

export const CalendarEventsTable = ({
  collapsed,
  selectedProject,
  isValidating,
}: {
  collapsed: boolean;
  isValidating: boolean;
  selectedProject?: string;
}) => {
  const projects = useStore((state) => state.projects);
  const setRoutes = useStore((state) => state.setRoutes);
  const searchTerm = useStore((state) => state.searchTerm);
  const filters = useStore((state) => state.filters);

  const keys = Object.keys(projects || {}).filter(
    (x) => x.search(searchTerm) > -1
  );

  const sortedProjects = useMemo(() => {
    return (
      projects &&
      (
        keys
          .map((key) => {
            return projects[key] ? projects[key] : undefined;
          })
          .filter((x) => x) as Project[]
      )
        .filter((x) => (filters.isDone ? !x.done : true))
        .sort((a, b) => (a.lastUpdate > b.lastUpdate ? -1 : 1))
    );
  }, [projects, keys, filters]);

  return (
    <div
      className={
        cls("wrapper") +
        " " +
        cls("wrapper--has-selected", Boolean(selectedProject))
      }
    >
      <SearchBar isNarrow={Boolean(selectedProject)} />
      {sortedProjects && (
        <table className={cls("table--loading", isValidating)}>
          <thead>
            <tr>
              <th>Client/Project</th>
              {!collapsed ? (
                <>
                  <th>Hours/Invoiced</th>
                  <th>Events</th>
                  <th>Money</th>
                </>
              ) : (
                <>
                  <th>Hours</th>
                </>
              )}
              <th>✓</th>
            </tr>
          </thead>
          <tbody>
            {sortedProjects.map((project) => (
              <tr
                onClick={() =>
                  selectedProject === project.key
                    ? setRoutes(["overview"])
                    : setRoutes(["overview", project.key])
                }
                key={project.key}
                className={cls(
                  "selected-project",
                  Boolean(selectedProject === project.key)
                )}
              >
                <td>
                  <ProjectKey project={project} />
                </td>
                {!collapsed ? (
                  <>
                    <td>
                      {(project.hours / 60).toFixed(2)}/
                      {(project.invoicedHours || 0).toFixed(2)}
                    </td>
                    <td>{project.events.length}</td>
                    <td>{project.money}</td>
                  </>
                ) : (
                  <>
                    <td>{(project.hours / 60).toFixed(2)}</td>
                  </>
                )}
                <td>{project.done ? "✓" : ""}</td>
              </tr>
            ))}
            <tr className={cls("table-footer")}>
              <td></td>
              <td>
                {(
                  sortedProjects
                    .map((x) => x.hours)
                    .reduce((x, y) => x + y, 0) / 60
                ).toFixed(2)}
                /
                {sortedProjects
                  .map((x) => x.invoicedHours)
                  .reduce((x, y) => x + y, 0)
                  .toFixed(2)}
              </td>
              {!Boolean(selectedProject) && (
                <>
                  <td>
                    {sortedProjects
                      .map((x) => x.events.length)
                      .reduce((x, y) => x + y, 0)}
                  </td>
                  <td>
                    {sortedProjects
                      .map((x) => x.money)
                      .reduce((x, y) => x + y, 0)}
                  </td>
                </>
              )}
              <td></td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};
